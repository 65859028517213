<template>
  <div class="cloud-pet-intelligence">
    <div class="wrap-content">
      <div class="top-title">
        <div class="left">
          <span>福福</span>
          <img :src="navImg" alt="" />
        </div>
        <div class="right">
          <img :src="navImg2" alt="" />
        </div>
      </div>
      <div class="content-box hide-scrollbar">
        <div class="tag_box">
          <div
            class="tag_item"
            v-for="(item, index) in tagList"
            :key="index"
            :class="{ active: item.index == index }"
          >
            <span>{{ item.name }}</span>
            <img :src="item.backgroundImg" alt="" />
          </div>
        </div>
        <div class="card_box">
          <div
            class="images-box"
            v-for="(item, index) in imagesList"
            :key="'img' + index"
          >
            <img :src="item.image" alt="" />
            <span>{{ item.name }}</span>
            <img class="bg_img" :src="cardImg" alt="" />
          </div>
        </div>
      </div>

      <div class="footer">
        <tabbarBox :tabbarData="tabbarData" :tabbarNum="3">
          <div class="tab_custom">
            <div class="custom_box">
              <div class="line"></div>
            </div>
          </div>
        </tabbarBox>
      </div>
    </div>
  </div>
</template>

<script setup>
import tabbarBox from "../tabbar/index.vue";
// 顶部盒子图片
const navImg = require("@/assets/images/imitatepage/cloud-pet-intelligence/icon_nav.png");
const navImg2 = require("@/assets/images/imitatepage/cloud-pet-intelligence/icon_nav2.png");

// card img
const cardImg = require("@/assets/images/imitatepage/cloud-pet-intelligence/card_bg.png");

// tag列表
const tagList = [
  {
    name: "设备",
    backgroundImg: require("@/assets/images/imitatepage/cloud-pet-intelligence/icon_tag.png"),
    index: 0,
  },
  {
    name: "提醒",
    index: "",
  },
];

//图片列表
const imagesList = [
  {
    image: require("@/assets/images/imitatepage/cloud-pet-intelligence/card-1.png"),
    name: "智能喂食器",
  },
  {
    image: require("@/assets/images/imitatepage/cloud-pet-intelligence/card-2.png"),
    name: "智能喂水器",
  },
  {
    image: require("@/assets/images/imitatepage/cloud-pet-intelligence/card-3.png"),
    name: "宠物背包",
  },
];

// tabbar
const tabbarData = [
  {
    icon: require("@/assets/images/imitatepage/cloud-pet-intelligence/tabbar/icon_tab.png"),
    text: "首页",
    color: "#00CEC8",
    isConvention: true,
    bold: 600,
  },
  {
    icon: "",
    text: "",
    isConvention: false,
    color: "#999999",
  },
  {
    icon: require("@/assets/images/imitatepage/cloud-pet-intelligence/tabbar/icon_tab2.png"),
    text: "我的",
    isConvention: true,
    color: "#7E7E7E",
  },
];
</script>

<style lang="scss" scoped>
.cloud-pet-intelligence {
  position: relative;
  width: 100%;
  height: 100%;

  .wrap-content {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 40px;
    // padding-bottom: 60px;
    box-sizing: border-box;
    // padding: 16px;
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr;
    padding: 0 2px;

    .top-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 60px;
      padding: 0 23px 0 29px;
      .left {
        display: flex;
        align-items: center;
        color: #101010;
        font-size: 24px;
        img {
          width: 15px;
          height: 10px;
          margin-top: 5px;
          margin-left: 8px;
        }
      }
      .right {
        img {
          width: 28px;
          height: 28px;
        }
      }
    }
    .content-box {
      //   height: calc(100% - 5px);
      overflow-y: auto;
      margin-top: 38px;
      padding: 0px 16px 30px;
      box-sizing: border-box;
      height: 100%;

      .card_box {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
        align-items: center;
        grid-column-gap: 14px;
        grid-row-gap: 14px;
        margin-top: 23px;
        .images-box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 4px;
          height: 160px;
          width: 160px;
          position: relative;
          box-shadow: 0 0 12px 8px #f3f7f7;
          border-radius: 10px;
          color: #333333;
          font-weight: bold;

          img {
            width: 52px;
            height: 52px;
            margin-bottom: 20px;
          }

          .bg_img {
            position: absolute;
            top: 0;
            left: 0;
            margin-bottom: 0;
          }
        }
      }

      .tag_box {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .tag_item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          color: #7e7e7e;
          font-weight: bold;

          img {
            width: 18px;
            height: 5px;
            margin-top: 3px;
          }

          &.active {
            color: #101010;
          }
        }
      }
    }

    // tabbar
    .footer {
      position: sticky;
      bottom: 0;
      width: 100%;
      box-shadow: 2px 0px 8px 1px #e4e3e3;
      box-sizing: border-box;
      z-index: 2;

      .tab_custom {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .custom_box {
        width: 52px;
        height: 40px;
        border: 3px solid #101010;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;

        .line {
          position: relative;
          width: 26px;
          height: 26px;
        }

        .line::before,
        .line::after {
          content: "";
          position: absolute;
          // top: 50%;
          width: 3px;
          height: 100%;
          background: #00cec8;
        }

        .line::before {
          left: 55%;
          transform: translate(-50%);
        }

        .line::after {
          top: 2px;
          left: 50%;
          transform: rotate(90deg) translate(-50%);
          transform-origin: center;
        }
      }
    }
  }
}
</style>
